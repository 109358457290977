import React from 'react'
import Layout from 'components/Layout'
import { css } from 'linaria'
import delve from 'dlv'

import rhythm from '../../utils/rhythm'

import { Lead, HeroHeading } from '../../components/TextElements'
import SimpleHeader from '../../components/Header/SimpleHeader'
import breakpoints from '../../utils/tokens/breakpoints'
import EstimateForm from '../../components/EstimateForm'

const hero = css`
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${rhythm(1)};
  }
`

export default ({ location }) => {
  let heroText = `We are JAMstack developers.`

  if (delve(location, 'state.prevLocation') === '/services/jamstack-website/') {
    heroText = (
      <>
        Need a blazing fast <mark>JAMstack Website</mark>?
      </>
    )
  }

  if (
    delve(location, 'state.prevLocation') === '/services/progressive-web-app/'
  ) {
    heroText = (
      <>
        Need a fast and scalable <mark>JAMstack PWA</mark>?
      </>
    )
  }

  return (
    <Layout
      location={location}
      title="Estimate project"
      description="Tell us about your project, get a quick estimate and a plan for making it live."
      image="https://bejamas-care.now.sh/**Estimate%20project**.png?theme=light&md=1&fontSize=100px&images=https%3A%2F%2Fassets-bejamas.now.sh%2Fbrand%2Fsvg%2FBejamas_logo_black.svg"
      customHeader={<SimpleHeader />}
    >
      <section className={hero}>
        <HeroHeading>{heroText}</HeroHeading>
        {heroText === `We are JAMstack developers.` && <h2>Who are you?</h2>}
        <Lead>
          Get a <strong>free one-to-one consultation</strong>.
        </Lead>
      </section>
      <EstimateForm />
    </Layout>
  )
}
