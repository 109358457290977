import React, { useState, useRef, useEffect } from 'react'
import { css } from 'linaria'
import { navigate } from 'gatsby'
import Button from '../Button'
import rhythm from '../../utils/rhythm'
import { colors, transition } from '../../utils/theme'
import hexToRgb from '../../utils/hexToRgb'
import breakpoints from '../../utils/tokens/breakpoints'
import { ga } from '../../utils/analytics'

const niceLabel = css`
  background: ${colors.background.lightGray};
  padding: ${rhythm(1 / 2)} ${rhythm(2 / 3)};
  display: block;
  cursor: pointer;
  border-radius: 16px;
  transition: box-shadow ${transition.speed.l} ${transition.curve.scaleIn},
    transform ${transition.speed.m} ${transition.curve.scaleIn},
    background ${transition.speed.m} ${transition.curve.scaleOut};

  &:hover {
    transform: scale(1.03) translate3d(0, 0, 0);
    box-shadow: 0 9px 40px 0 rgba(${hexToRgb(colors.text.darkGray)}, 0.25);
  }
`

const form = css`
  display: grid;
  row-gap: ${rhythm(1)};
  max-width: ${rhythm(26)};
  margin: ${rhythm(2)} auto ${rhythm(4)};

  input[type='radio']:checked + label {
    background: ${colors.accent.purple};
    color: #fff;
  }

  input[type='radio']:focus + label {
    background: ${colors.accent.purple};
    color: #fff;
    box-shadow: 0 0 30px 0 rgba(${hexToRgb(colors.accent.purple)}, 0.65);
  }

  textarea {
    resize: vertical;
    min-height: 80px;
  }
`

const input = css`
  border-radius: 28px;
  background: ${colors.background.lightGray};
  padding: ${rhythm(2 / 3)} ${rhythm(1)};
  box-shadow: none;
  border: 2px solid ${colors.background.lightGray};

  &:focus {
    border: 2px solid ${colors.accent.purple};
    outline: 0;
  }
`

const radioContainer = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > div {
    margin-right: ${rhythm(1 / 2)};

    @media (max-width: ${breakpoints.md}) {
      margin-top: ${rhythm(1 / 2)};
    }
  }

  p {
    margin: 0 ${rhythm(1 / 2)} 0 0;

    @media (max-width: ${breakpoints.md}) {
      width: 100%;
      margin-right: 0;
    }
  }

  input[type='radio'] {
    position: absolute;
    left: -9999em;
  }
`

const projectTypes = ['Website', 'PWA', 'eCommerce', 'Other']

async function sendForm(data) {
  const visitedPages = window.localStorage.getItem('visitedPages') || ''

  try {
    const response = await fetch('https://api.bejamas.io/v1/send-form', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...data,
        visitedPages,
        isJs: true
      })
    })

    ga('send', {
      hitType: 'event',
      eventCategory: 'Forms',
      eventAction: 'booking',
      eventLabel: 'Form Sent'
    })

    return [true, false]
  } catch (e) {
    ga('send', {
      hitType: 'event',
      eventCategory: 'Forms',
      eventAction: 'booking',
      eventLabel: 'Form Sent Failed'
    })

    return [false, true]
  }
}

const EstimateForm = () => {
  const inputName = useRef(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [projectType, setType] = useState('Website')
  const [sending, setSending] = useState(false)

  useEffect(() => {
    inputName.current.focus()
  }, [])

  const sendRequest = async e => {
    e.preventDefault()
    setSending(true)

    const [sended, error] = await sendForm({
      name,
      email,
      projectType,
      message
    })

    if (sended) {
      setSending(false)
      navigate('/estimate-project/thank-you/', {
        state: {
          name
        }
      })
    }
  }

  return (
    <form
      action="https://api.bejamas.io/v1/send-form"
      method="POST"
      className={form}
      onSubmit={sendRequest}
    >
      <input
        placeholder="Your name *"
        type="text"
        className={input}
        value={name}
        name="name"
        ref={inputName}
        onChange={e => setName(e.target.value)}
        autoFocus
        required
      />
      <input
        placeholder="Your business email *"
        type="email"
        name="email"
        className={input}
        value={email}
        onChange={e => setEmail(e.target.value)}
        required
      />
      <div className={radioContainer}>
        <p>Select type of project: </p>
        {projectTypes.map(type => (
          <div key={`type_input__${type}`}>
            <input
              type="radio"
              id={`type_${type}`}
              name="projectType"
              value={type}
              checked={projectType === type}
              onChange={e => setType(e.target.value)}
            />
            <label htmlFor={`type_${type}`} className={niceLabel}>
              {type}
            </label>
          </div>
        ))}
      </div>

      <textarea
        placeholder="How can we help?"
        rows="4"
        className={input}
        name="message"
        onChange={e => setMessage(e.target.value)}
      >
        {message}
      </textarea>
      {/* <input type="checkbox" id="needNda" />
      <label htmlFor="needNda">I need to sign NDA first.</label> */}
      <Button tag="button" type="submit" diabled={sending}>
        {sending ? 'Sending...' : 'Request Free Consultation Now!'}
      </Button>
    </form>
  )
}

export default EstimateForm
