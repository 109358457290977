import React from 'react'
import { css } from 'linaria'
import { ReactComponent as Logo } from 'assets/svg/logo-black.svg'
import rhythm from '../../utils/rhythm'
import breakpoints from '../../utils/tokens/breakpoints'
import { colors, transition } from '../../utils/theme'
import { Link } from 'gatsby'

const header = css`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9;
  height: 100px;

  @supports (backdrop-filter: blur(20px)) {
    background: ${colors.background.almostPrimary};
    backdrop-filter: blur(16px);
  }
`

const container = css`
  display: grid;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'logo cta burger'
    'nav nav nav';

  @media (max-width: ${breakpoints.lg}) {
    padding: ${rhythm(1 / 2)} 8vw;
    grid-template-rows: 60px auto;
    z-index: 8;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: ${rhythm(1 / 2)} 4vw;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: 'logo nav cta';
    padding: 0 4vw;
  }

  @media (min-width: ${breakpoints.xxl}) {
    max-width: 87.5rem;
    padding: 0;
  }

  @media (max-width: ${breakpoints.md}) {
    grid-template-rows: 44px auto;
  }

  .cta {
    transition: all ${transition.speed.xl} ${transition.curve.ease};
  }
`

const logoArea = css`
  grid-area: logo;
  z-index: 8;
`

const logo = css`
  max-width: 6.25rem;
  height: 30px;
  margin-top: 0.5rem;

  @media (min-width: ${breakpoints.md}) {
    max-width: 11.5rem;
    max-width: ${rhythm(6)};
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: ${rhythm(7)};
  }

  path {
    fill: ${colors.text.solid};
  }
`

const cta = css`
  grid-area: cta;
  margin-left: auto;

  a {
    text-decoration: none;
    color: ${colors.text.lightGray};
    padding: ${rhythm(1 / 2)} 0;

    &:hover {
      color: ${colors.accent.purple};
    }
  }
`

const mobile = css`
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const desktop = css`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const SimpleHeader = () => (
  <header className={header}>
    <div className={container}>
      <div className={logoArea}>
        <Link to="/">
          <Logo className={logo} viewBox="0 0 1823.23 288.662" />
        </Link>
      </div>
      <div className={cta}>
        <Link to="/">
          <span className={mobile}>Go to homepage.</span>
          <span className={desktop}>No, I don't want any advices.</span>
        </Link>
        {/* <Link to='/'>Back to homepage</Link> */}
      </div>
    </div>
  </header>
)

export default SimpleHeader
